.rbc-off-range-bg {
  background: #fff;
}
.rbc-header {
  text-align: end;
}
.rbc-month-view {
  border: 0;
}
.rbc-day-bg + .rbc-day-bg,
.rbc-header + .rbc-header {
  border-left: 0;
}
.rbc-row-bg {
  row-gap: 10px;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background: rgba(0, 0, 0, 0.027);
  color: #000;
}
.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background: rgba(0, 0, 0, 0.027);
}
