.container {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin: 30px auto 0 auto;
  background-color: #fff;
  border-radius: 7px;
  padding-bottom: 10px;
  @media screen and (max-width: 1330px) {
    width: 100%;
  }
  .actionIcon {
    display: flex;
    justify-content: space-evenly;
    font-size: medium;
  }

  .buttonAdd {
    padding: 8px 15px;
    border: #7a7a7a 1px dashed;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: initial;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid rgb(223, 229, 234);

    h4 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      margin: 0;
    }
    subtitle {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
    }

    button {
      color: rgb(55, 153, 235);
      background-color: transparent;
      border: 0;
      outline: none;
      cursor: pointer;
    }
  }
  .subHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
  }

  .field {
    margin: 10px 0;
    // padding: 5px 20px;
    p {
      font-weight: 500;
      margin-bottom: 10px;
    }
    input[type="text"],
    select,
    textarea {
      width: 100%;
      border: #f8f8f8;
      background-color: #f8f8f8;
      border-radius: 5px;
      padding: 10px 5px;
      outline: none;
      resize: vertical;
    }
  }
}

.cardList {
  padding: 20px;

  .card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
  }
  .imageFooter {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 30px;
    color: #bfbfbf;

    span {
      margin-left: 5px;
    }
  }
  .buttonWrap {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0px;
    button {
      padding: 8px 62px;
      border-radius: 10px;
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;

  button {
    width: 120px;
  }
  .secondaryButton {
    background-color: #fff;
    color: grey !important;
    margin-right: 20px;
    border: grey !important;
  }
}

.table {
  margin-top: 20px;
  width: 100%;
  background-color: rgb(251, 251, 251);
  thead {
    background-color: #000;
    font-size: 16px;
    color: #fff;
    tr {
      th {
        // padding: 8px 0 8px 5%;
        font-size: 14px;
        padding: 12px 8px;
        &:first-child {
          border-radius: 5px 0 0 5px;
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        // padding-left: 5%;
        // padding: 5px 0 5px 5%;
        padding: 12px 8px;
        font-size: 14px;

        input {
          width: 100%;
          background: inherit;
          border: none;
        }

        svg {
          cursor: pointer;
          font-size: 20px;
          margin-left: 10px;

          transition: all 0.1s;
          &:hover {
            transform: scale(1.1);
          }
          &:nth-child(1) {
            color: #afafaf;
          }
          &:nth-child(2) {
            color: #5cb559;
          }
          &:nth-child(3) {
            color: #d34c43;
          }
        }
      }
    }
  }
}
