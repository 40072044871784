.container {
  .ticketsContainer {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px 25px;
    h4 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      margin: 0;
    }
    .filters {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > div {
        background-color: #000;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 170px;
        margin-left: 10px;
        height: 40px;
        border-radius: 5px;
        padding-left: 10px;
        cursor: pointer;
        &:active {
          transform: translateY(1px);
        }
        div {
          padding: 0 10px;
          border-left: 1px solid #8f8b8a;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    table {
      width: 100%;
      background-color: rgb(251, 251, 251);
      border-radius: 5px;
      overflow: hidden;
      thead {
        background-color: #000;
        color: #fff;
        tr {
          th {
            padding: 10px 5px;
            &:first-child {
              border-radius: 5px 0 0 5px;
            }
            &:last-child {
              border-radius: 0 5px 5px 0;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 5px;
            font-size: 22px;
            svg {
              cursor: pointer;
              font-size: 30px;
              margin-right: 5px;
              transition: all 0.1s;
              &:hover {
                transform: scale(1.1);
              }
              &:nth-child(1) {
                color: #afafaf;
              }
              &:nth-child(2) {
                color: #5cb559;
              }
              &:nth-child(3) {
                color: #d34c43;
              }
            }
          }
        }
      }
    }
  }
}
