.compareSection {
  padding: 20px 30px;
  background-color: #fff;

  button {
    width: 100%;
    border-color: black;
    background-color: black;
    color: white;
    padding: 15px;
    border-radius: 10px;

    span {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin-left: 10px;
    }
  }

  .watchButton {
    background-color: white;
    color: black;
  }
}

.grayTitle {
  color: grey;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}

.table {
  width: 100%;
  overflow-x: auto;
  
  table {
    width: 100%;
  }
  td, th {
    padding: 10px 20px;
  }
  td {
    color: grey;
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  th {
    color: black;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
  }
}

.accordionSection {
  .bodyTitle {
    h1 {
      font-weight: 600;
      margin-top: 20px;
    }
  }
  .bodyDescCol {
    h2 {
      color: grey;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    div {
      padding: 0px 20px;

      .colListDesc {
        font-size: 21px;
        margin-top: 10px;
        color: #000;
        margin-bottom: 30px;
        span {
          word-wrap: break-word;
        }
      }

      ul {
        margin-top: 10px;
        list-style: none;
        padding-left: 0px;
        text-align: left;

        li {
          color: grey;
          font-weight: 600;
          font-size: 18px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .listHead {
          color: black;
          margin-bottom: 10px;
          font-weight: 600;
          font-size: 20px;
          text-transform: capitalize;
        }
      }
    }
  }
}

.headerInfoPicture {
  background-image: url("../../Assets/images/piLogo.png");
  width: 98.4%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px 0px 0px 0px;
}

.headerInfo {
  border-radius: 10px 0px 0px 0px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  position: relative;

  svg {
    font-size: 54px;
    color: #fff;
  }

  h2 {
    color: #fff;
    font-weight: 800;
    font-size: 42px;
  }

  p {
    color: #fff;
    font-size: 24px;
  }
}

.headerInfoDetail {
  background-color: #000;
  z-index: 1;
  .innerDiv {
    width: 100%;
    height: 100%;
    padding: 50px 20px;
  }
  .middleCard {
    text-align: center;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    margin: 15px 0px;
    padding: 15px 0px;
    p {
      color: grey;
    }
    h4 {
      color: #fff;
    }
  }
  .lastCard {
    text-align: center;
    border-top: 1px solid grey;
    margin: 15px 0px;
    padding: 15px 0px;
    p {
      color: grey;
    }
    h4 {
      color: #fff;
    }
  }
  .Card {
    p {
      color: grey;
    }
    h4 {
      color: #fff;
    }
  }
}

.feed {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 15px;
  padding: 15px;

  .tableContainer {
    max-height: 17rem;
    overflow-y: visible; /* Always show vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    margin-top: 20px;
    padding: 0;
    scrollbar-width: thin; /* For Firefox */

    &::-webkit-scrollbar {
      width: 8px; /* Adjust width as needed */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888; /* Change this to your preferred color */
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* Change this to your preferred hover color */
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 800;
  }

  hr {
    color: rgba(0, 0, 0, 0.486);
  }

  & > p {
    font-weight: 700;
    font-size: 25px;
  }

  table {
    margin-top: 0;
    width: 100%;
    background-color: rgb(251, 251, 251);

    thead {
      background-color: #000;
      font-size: 16px;
      color: #fff;
      position: sticky;
      top: 0;

      tr {
        th {
          padding: 12px 8px;

          &:first-child {
            border-radius: 5px 0 0 5px;
          }

          &:last-child {
            border-radius: 0 5px 5px 0;
          }
        }
      }
    }

    tbody {
      
      tr {
        td {
          padding: 12px 8px;
          font-size: 14px;

          svg {
            cursor: pointer;
            font-size: 20px;
            margin-left: 10px;

            transition: all 0.1s;

            &:hover {
              transform: scale(1.1);
            }

            &:nth-child(1) {
              color: #afafaf;
            }

            &:nth-child(2) {
              color: #5cb559;
            }

            &:nth-child(3) {
              color: #d34c43;
            }
          }
        }
      }
    }
  }
}

.container {
  width: 90%;
  height: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin: 30px auto 0 auto;
  background-color: #fff;
  border-radius: 7px;
  padding-bottom: 10px;

  @media screen and (max-width: 1330px) {
    width: 100%;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid rgb(223, 229, 234);
  }
}
