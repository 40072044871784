.team {
    width: 100px;
}


.singleInputs td {
    width: 34px !important;
    text-align: center;
}

.singleInputs input {
    width: 30px !important;
}

.singleInputs span {
    font-weight: bold;
}


.doubleInputs {
    width: 100px !important;
    text-align: center;
    input {
        width: 40px;
    }

    span {
        font-weight: bold;
    }
}


.table {
    margin:0;
    min-width: 800px;
}