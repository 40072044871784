.container {
  .calendarContainer {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px 25px;
    h4 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      margin: 0;
    }
    .calendar {
      height: 700px;
    }
  }
}
.eventBadge {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  border-radius: 50%;
  &.error {
    background-color: red;
  }
  &.success {
    background-color: green;
  }
  &.warning {
    background-color: yellow;
  }
}
